<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="delivery-fee"
        navigation="automatic-line-items"
        :onNew="newItem"
        title="Delivery Fee">
        <template slot-scope="{ item, data }">
            <v-row class="mt-0 pt-0">
                <v-col cols="6">
                    <div class="text-center">
                        <v-icon size="150" color="primary">mdi-map-marker-distance</v-icon>
                    </div>
                </v-col>
                <v-col cols="6">
                    <BT-Field-String
                        v-model="item.lineItemName"
                        label="Delivery Fee Name"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-String
                        v-model="item.description"
                        label="Description"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Number
                        v-model.number="item.sortNumber"
                        label="Sort Number"
                        :isEditing="data.isEditing || data.isNew" />

                    <v-row class="my-2 px-3">
                        
                        <v-spacer />

                        <BT-Sidebar-Mappings
                            inline
                            isEditing
                            :itemID="item.id"
                            label="Mappings"
                            navigation="order-invoice-mappings"
                            small />

                        <BT-Sidebar-External-Links
                            inline
                            :itemID="item.id"
                            label="Integrate"
                            navigation="automatic-line-item"
                            small
                            syncNavigation="automatic-line-item-syncing" />
                    </v-row>
                </v-col>
            </v-row>

            <v-divider class="my-2" />

            <v-row>
                <v-col cols="6">
                    <BT-Field-Number
                        :dense="false"
                        v-model.number="item.quantity"
                        label="Value Under"
                        prependIcon="mdi-currency-usd"
                        textFilter="toCurrency"
                        :isEditing="data.isEditing || data.isNew" />

                    <BT-Field-Select
                        :dense="false"
                        :items="['FixedValue', 'Percent']"
                        v-model="item.lineValueType"
                        label="Line Value Type"
                        :isEditing="data.isEditing || data.isNew" />
                    
                    <BT-Field-Number
                        :dense="false"
                        v-model.number="item.lineValue"
                        :label="`Unit Price / Value (${item.lineValueType == 'Percent' ? '% (0.10 is 10%)' : '$$$'})`"
                        :isEditing="data.isEditing || data.isNew" />
                </v-col>
                <v-col cols="6">
                    <v-list-item dense>
                        <v-list-item-content>
                            <BT-Tax-Type
                                v-model="item.taxType"
                                :disabled="!data.isEditing && !data.isNew" />
                        </v-list-item-content>
                    </v-list-item>

                    <BT-Field-Checkbox
                        v-model="item.isLastMinute"
                        label="Calculate Last Minute"
                        :isEditing="data.isEditing || data.isNew" />
                    
                    <BT-Field-Switch
                        v-model="item.isOn"
                        label="Is On"
                        :isEditing="data.isEditing || data.isNew" />

                </v-col>
            </v-row>
            
            <v-divider class="my-2" />

            <v-row>
                <v-col cols="12">
                    <v-subheader>Delivery Fee Area</v-subheader>
                    <GmapMap
                        :center="center"
                        :zoom="7"
                        style="width: 100%; min-height: 300px; height: 430px;"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: true,
                            disableDefaultUi: false }">

                            <gmap-polygon 
                                :paths="item.boundary"
                                :draggable="data.isEditing || data.isNew"
                                @paths_changed="($event) => { updatePointerPaths($event, item) }"
                                :editable="data.isEditing || data.isNew"
                                :fillOpacity="0.5"
                                class="polygon" />
                        </GmapMap>
                </v-col>
            </v-row>

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Automatic-Line-Item-Blade',
    components: {
        BTSidebarExternalLinks: () => import('~components/BT-Sidebar-External-Links.vue'),
        BTSidebarMappings: () => import('~components/BT-Sidebar-Mappings.vue'),
    },
    data: function() {
        return {
            center: { lat: -38, lng: 144 },
            currentLine: null,
            isNewLine: false,
            panelV: null,
            showEdit: false,
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        ruleOptions() {
            return [
                { text: 'Product Value Greater Than', value: 'ProductValueGreaterThan' },
                { text: 'Product Value Less Than', value: 'ProductValueLessThan' },
                { text: 'Product Value Equal To', value: 'ProductValueEqualTo' },
                { text: 'Total Value Greater Than', value: 'TotalValueGreaterThan' },
                { text: 'Total Value Less Than', value: 'TotalValueLessThan' },
                { text: 'Total Value Equal To', value: 'TotalValueEqualTo' },
                { text: 'Total Quantity Greater Than', value: 'TotalQuantityGreaterThan' },
                { text: 'Total Quantity Less Than', value: 'TotalQuantityLessThan' },
                { text: 'Total Quantity Equal To', value: 'TotalQuantityEqualTo' },
                { text: 'Product Exists', value: 'ProductExists' }
            ];
        }
    },
    methods: {
        isValueBased(lineItem) {
            return lineItem.rule == 'ProductValueGreaterThan' || lineItem.rule == 'ProductValueLessThan' || lineItem.rule == 'ProductValueEqualTo' ||
                lineItem.rule == 'TotalValueGreaterThan' || lineItem.rule == 'TotalValueLessThan' || lineItem.rule == 'TotalValueEqualTo';
        },
        newItem() {
            return {
                sortNumber: 1,
                rule: 'ProductValueLessThan',
                quantity: 1,
                optionGroupID: 'Delivery Fee',
                lineValueType: 'FixedValue',
                lineValue: 0,
                linesWithTags: null,
                lineItemName: null,
                itemID: null,
                isOn: true,
                isReplacement: false,
                isLastMinute: false,
                description: 'Delivery Fee',
                calcOrder: 10,
                boundary: this.getAreaAround({ lat: -38, lng: 144 }, 1),
                tags: null,
                taxType: 'GST'
            }
        },
        updatePointerPaths(mvcArray, zone) {
            let paths = [];
            for (let i = 0; i < mvcArray.getLength(); i++) {
              let path = [];
              for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({ lat: point.lat(), lng: point.lng() });
              }
              paths.push(path);
            }
            zone.boundary = paths[0];
        },
    }
}
</script>